* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.wrapper {
  margin: 0 auto;
  width: 90%;
  max-width: 1300px;
}

#root {
  width: 100%;
  overflow-x: hidden;
}

/*POPUP*/

.popup {
  height: 100%;
  width: 100%;
  background-image: url("./assets/placa.jpeg");
  background-size: cover;
  position: fixed;
  z-index: 4000000;
  background-position: center;
  transform: translate3d(0, -100%, 0);
  transition: all 1s;
}

.popup-visible {
  transform: translate3d(0, 0, 0);
}

/*HEADER*/

.top-header {
  background-color: #0059b3;
  padding: 8px;
}

.top-header-details {
  display: flex;
  align-items: center;
  justify-content: end;
}

.top-header-details a {
  text-decoration: none;
  color: white;
  font-weight: 500;
  font-size: 14px;
  margin-left: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-header-details p {
  color: white;
  font-size: 14px;
  font-weight: 500;
}

.hamburguer-menu {
  cursor: pointer;
  border: none;
  background-color: transparent;
  z-index: 4;
  height: 40px;
  margin-right: 20px;
}

.hamburguer-menu span {
  border: 2px solid black;
  border-radius: 3px;
  margin: 8px 0;
  display: block;
  width: 43px;
  transition: ease-out 0.3s;
}

.hamburguer-menu span:nth-child(1) {
  transform-origin: 50% 50%;
}

.hamburguer-menu.open span:nth-child(1) {
  transform: translateY(11px) rotate(45deg);
}

.hamburguer-menu.open span:nth-child(2) {
  opacity: 0;
  transform: translateX(-30px);
}

.hamburguer-menu span:nth-child(3) {
  transform-origin: 50% 50%;
}

.hamburguer-menu.open span:nth-child(3) {
  transform: translateY(-12px) rotate(-45deg);
}

.bottom-header-details {
  padding: 20px;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 3000;
  margin-left: 40px;
}

.logo-principal {
  width: 300px;
}

.logo-principal-complementario {
  width: 300px;
}

.logo-principal-responsive {
  display: none;
  width: 300px;
}

.wp-top-header {
  width: 20px;
  margin-right: 5px;
}

/*HEADER COMPLEMENTARIO*/

.container-header-complementario {
  background-color: #0059b3;
  padding: 20px;
  border-bottom: 2px solid rgb(0, 0, 0);
}

.details-header-complementario {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.close-header-complementario {
  width: 30px;
  cursor: pointer;
}

/*TERMINOS Y CONDICIONES*/

.container-terms {
  margin-top: 30px;
  margin-bottom: 30px;
}

.container-terms h1 {
  margin-bottom: 30px;
}

.container-terms h2 {
  margin: 20px 0 10px 0;
}

.container-terms p {
  margin-bottom: 5px;
  line-height: 26px;
}

.container-terms a {
  text-decoration: none;
  font-weight: bold;
  color: rgb(237, 51, 85);
}

/*CONTACT*/

.container-detail-contacto {
  width: 50%;
}

.form-container {
  border-radius: 5px;
  width: 100%;
}

.form-container input,
.form-container textarea {
  width: 100%;
  border: 3px solid rgba(0, 0, 0, 0.552);
  padding: 14px 0;
  margin-bottom: 20px;
  margin-top: 5px;
  border-radius: 5px;
}

.form-container textarea {
  resize: none;
}

.button-contact {
  text-decoration: none;
  text-transform: uppercase;
  color: black;
  background-color: #e3e4e6;
  padding: 12px 24px;
  margin-bottom: 30px;
  font-weight: bold;
  border-radius: 5px;
  margin-top: 20px;
  font-size: 18px;
  cursor: pointer;
}

.button-contact[disabled] {
  opacity: 0.5;
  background-color: grey;
  cursor: auto;
}

.container-contacto {
  position: relative;
  background-image: url("./assets/contact.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.container-contacto::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Ajusta la opacidad según sea necesario */
  z-index: 1;
}

.container-detail-contacto {
  position: relative;
  z-index: 2; /* Para que el contenido esté por encima de la capa oscura */
}

.container-contacto h1 {
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 50px;
  font-size: 32px;
  letter-spacing: 8px;
  text-align: center;
}

/*FUNCTIONING*/ /*WHOWEARE*/

.info-footer-cont p {
  color: #fff;
}

.info-footer-cont {
  margin-bottom: 15px;
}

.container-functioning,
.container-who-we-are {
  display: grid;
  grid-template-columns: minmax(0, 50fr) minmax(0, 50fr);
  align-items: center;
  margin-top: 55px;
  margin-bottom: 55px;
}

.container-functioning h1,
.container-who-we-are h1 {
  margin-bottom: 30px;
  text-transform: uppercase;
}

.container-functioning p,
.container-who-we-are p {
  line-height: 24px;
  margin-bottom: 20px;
}

.container-functioning img,
.container-who-we-are img {
  width: 100%;
  margin-left: 20px;
  border-radius: 4px;
}

/*QUESTIONS*/

.container-frecuent-questions {
  margin-top: 30px;
  margin-bottom: 30px;
}

.container-frecuent-questions h1 {
  margin-bottom: 30px;
}

.container-frecuent-questions h2 {
  margin: 20px 0 10px 0;
  font-weight: 600;
}

.container-frecuent-questions h3 {
  font-weight: 400;
  margin-bottom: 5px;
  font-size: 18px;
}

.details-questions {
  margin-top: 40px;
}

.doubts {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 25px;
}

.details-questions a {
  text-decoration: none;
  text-transform: uppercase;
  color: black;
  border: 1px solid black;
  background-color: #e3e4e6;
  padding: 8px 16px;
  margin-bottom: 30px;
  font-weight: bold;
  border-radius: 5px;
}

/*NAVBAR*/

nav ul li {
  list-style: none;
  padding: 10px 0;
}

nav ul li a {
  text-decoration: none;
  font-weight: 500;
  text-transform: uppercase;
}

nav {
  width: 400px;
  position: absolute;
  z-index: 20000;
  background: #0059b3;
  height: 300px;
  opacity: 0.9;
  padding: 20px;
  top: 120px;
  left: 40px;
}

/*  .close-nav {
    cursor: pointer;
    border: none;
    color: white;
    font-size: 20px;
    font-weight: 500;
    background-color: transparent;
    margin-bottom: 30px;
    color: black;
  } 

  .close-nav img {
    width: 20px;
  }
  */

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.nav-link {
  font-size: 20px;
  font-weight: 300;
  color: white;
}

/*MAIN*/

/*video*/

.principal-container {
  min-height: 450px;
  width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  position: relative;
}

.principal-container video {
  width: 100%;
  position: relative;
}

.video-responsive {
  display: none;
}

.text-main {
  background-color: #faf7d7d6;
  padding: 20px;
  font-weight: 400;
}
.text-main h1 {
  text-align: center;
  font-size: 22px;
  color: #000;
  text-shadow: 1px 0.5px 4px #6ba7c5;
  text-transform: uppercase;
  font-family: "Ubuntu", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3rem;
}
.text {
  position: absolute;
  z-index: 2;
  text-align: start;
  top: 35vw;
  right: 0;
  left: 10vw;

  width: 50%;
  text-shadow: 2px 2px 10px rgb(0, 0, 0);
  color: #ffffff;
}

.cursor-text {
  display: none;
}

.texts-spans {
  text-transform: uppercase;
  font-size: 2.5rem;
  list-style: none;
  top: 0;
  position: relative;
  font-weight: 700;
}

/*EQUIPMENT*/

.equipment-container h1 {
  margin-top: 40px;
  margin-bottom: 20px;
}

.equipment-detail {
  margin: 30px 0;
  border: 1px solid;
  padding: 30px;
  background-color: #0059b3;
  color: white;
  height: 95%;
  border-radius: 5px;
}

.iframe-video-open {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.iframe-video-open iframe {
  max-width: calc(100%);
  max-height: calc(100% - 8rem);
}

.button-particular .close-button-equipment {
  width: 50px;
  position: absolute;
  top: 30px;
  right: 50px;
  background-color: transparent;
  border: none;
}

.iframe-video-close {
  display: none;
}

.equipment-principal {
  text-align: center;
  text-transform: uppercase;
}

.equipment-detail img {
  width: 100%;
  border: 1px solid white;
  background: white;
  border-radius: 5px;
}

.equipment-detail button {
  border: 1px solid #0059b3;
  text-decoration: none;
  color: white;
  background-color: #0059b3;
  border-radius: 2px;
  padding: 5px 10px;
  cursor: pointer;
}

.equipment-detail ul {
  padding: 0px 15px;
}

.second-list {
  margin-bottom: 30px;
}

.details-container-info {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 50fr));
  padding: 10px;
  column-gap: 60px;
}

.sub-equipment {
  text-transform: uppercase;
  margin-bottom: 10px;
}

.sub2 {
  margin-bottom: 15px;
}

/*GOOGLE*/

.google-reviews {
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
  align-items: center;
  justify-content: center;
}

.google-container h1 {
  text-align: center;
  text-transform: uppercase;
  font-weight: 400;
}

.google-container {
  margin-left: 90px;
  width: 400px;
}

.info-google {
  display: block;
}

.google-container img {
  width: 100%;
  height: 320px;
  margin-top: 10px;
  text-align: center;
}

.google-container a {
  text-align: center;
  text-decoration: none;
  color: blue;
  font-weight: 500;
}

.cotizacion-container {
  text-align: center;
}

.cotizacion-container img {
  width: 40px;
  margin-right: 10px;
}

.cotizacion-container p {
  font-weight: 300;
  font-size: 18px;
}

.cotizacion-container h1 {
  text-transform: uppercase;
  margin-bottom: 15px;
  font-weight: 400;
}

.cotizacion-container a {
  text-decoration: none;
  color: black;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  font-weight: bold;
}

.brand img {
  width: 120px;
}

.container-brand {
  margin: 10px 0;
}

.brand img {
  width: 180px;
}

/*BENEFITS*/

.benefits-container {
  background-color: #0059b3;
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 50fr));
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 20px 80px;
}

.benefits-details {
  margin: 50px 20px;
}

.benefits-details p {
  font-weight: 600;
  margin-top: 20px;
  color: white;
  font-size: 14px;
}

.benefits-details img {
  width: 40px;
}

/*GALERIA*/

.galery-home-container {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 50fr));
}

.galery-home-details {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.galery-home-details img {
  width: 100%;
  display: block;
  object-fit: cover;
  height: 100%;
  position: absolute;
}

.text-galery {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}

.text-galery:hover {
  background: rgba(40, 40, 40, 0.6);
}

.text-galery:before,
.text-galery:after {
  content: "";
  width: 80%;
  height: 2px;
  background: white;
  position: absolute;
  display: block;
  left: 10%;
  transform: scaleX(0);
  transition: all 0.3s;
}

.text-galery:before {
  top: 10%;
  transform-origin: 0% 50%;
}

.text-galery:after {
  bottom: 10%;
  transform-origin: 100% 50%;
}

.galery-home-details:hover .text-galery:before,
.galery-home-details:hover .text-galery:after {
  transform: scaleX(1);
}

.text-galery span {
  opacity: 0;
  transform: scale(0);
  color: #fff;
  font-size: 30px;
  transition: all 0.3s;
}

.galery-home-details:hover .text-galery span {
  opacity: 1;
  transform: scale(1);
}

/*FOOTER*/

.footer-container {
  background-color: #0059b3;
  border-top: 1px solid rgb(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.footer-details {
  display: flex;
  padding: 30px 0;
  align-items: center;
  justify-content: space-between;
}
.logo-footer {
  width: 200px;
}

.redes-container {
  display: flex;
  align-items: center;
}

.redes-container img {
  width: 30px;
  margin: 0 5px;
}

.terms-container {
  margin-bottom: 15px;
}

.terms {
  text-decoration: none;
  color: #fff;
}

.logo-hg img {
  width: 35px;
}

.logo-hg {
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 14px;
}

@media screen and (max-width: 1024px) {
  .details-container-info {
    display: block;
  }

  .benefits-details img {
    width: 70px;
  }

  .galery-home-container {
    grid-template-columns: repeat(2, minmax(0, 50fr));
  }
}

@media screen and (max-width: 900px) {
  .contact-map {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo-hg {
    justify-content: center;
  }
  .footer-container {
    display: block;
  }
  .popup {
    height: 100%;
    width: 100%;
    background-image: url("./assets/placa-responsive.jpeg");
    background-size: cover;
    position: fixed;
    z-index: 4000000;
    background-position: center;
    transform: translate3d(0, -100%, 0);
    transition: all 1s;
  }

  .popup-visible {
    transform: translate3d(0, 0, 0);
  }

  .container-functioning,
  .container-who-we-are {
    display: block;
  }

  .container-functioning img,
  .container-who-we-are img {
    margin-left: 0;
  }

  .container-contacto {
    display: block;
  }

  .container-detail-contacto {
    width: 100%;
    padding: 0 50px;
  }

  .footer-details {
    display: block;
    text-align: center;
  }

  .logo-footer,
  .terms-container,
  .info-footer-cont {
    margin-bottom: 20px;
  }

  .redes-container {
    display: block;
  }

  .google-reviews {
    display: block;
  }

  .cotizacion-container {
    margin-bottom: 50px;
  }

  .google-container {
    text-align: center;
    margin: 0 auto;
  }

  .brand img {
    width: 90px;
  }
}

@media screen and (max-width: 800px) {
  .close-header-complementario {
    width: 15px;
  }

  .background-video {
    display: none;
  }

  .logo-principal {
    display: none;
  }

  .logo-principal-responsive {
    display: block;
  }

  .logo-principal-complementario {
    width: 200px;
  }

  .video-responsive {
    display: block;
  }

  .top-header-details {
    display: block;
    justify-content: center;
  }

  .top-header-details a {
    margin-left: 0;
    text-align: center;
  }

  .top-header-details p {
    text-align: center;
    margin-bottom: 2px;
  }

  .wrapper-top-header {
    width: 100%;
  }
  .bottom-header {
    background-color: #0059b3;
    border-top: 1px solid #fafafc3b;
    border-bottom: 1px solid #fafafc3b;
  }

  nav {
    top: 144px;
    left: 0;
    opacity: 1;
    right: 0;
    width: 100%;
  }

  .nav-container {
    display: block;
    text-align: center;
  }

  .nav-link {
    font-size: 16px;
  }

  nav ul li a:hover {
    font-size: 20px;
  }

  .bottom-header-details {
    padding: 20px;
    justify-content: space-around;
    margin-left: 20px;
    flex-direction: row-reverse;
    align-items: center;
    position: static;
  }

  .benefits-container {
    grid-template-columns: repeat(2, minmax(0, 50fr));
    padding: 10px 30px;
  }

  .benefits-container img {
    width: 100px;
  }
}

@media screen and (max-width: 600px) {
  .logo-footer {
    width: 220px;
  }
  .hamburguer-menu {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    margin-left: 10px;
  }

  .brand img {
    width: 70px;
  }

  .galery-home-container {
    display: grid;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 500px) {
  .logo-principal-responsive {
    width: 250px;
  }

  .cotizacion-container h1,
  .cotizacion-container a {
    font-size: 26px;
  }
  .cotizacion-container img {
    width: 30px;
  }

  .cotizacion-container p {
    font-size: 14px;
  }

  .google-container {
    width: 350px;
  }

  .benefits-container img {
    width: 50px;
  }

  .benefits-container p {
    font-size: 12px;
  }

  .brand img {
    width: 70px;
    height: 80px;
  }

  .texts-spans {
    font-size: 1.5rem;
  }

  .text {
    top: 30vw;
  }
}
